<template>
  <div class="wrapper">
    <b-card no-body bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image5.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11087') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion1'" variant="secondary">{{ $t('wdm16.11088') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion1" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11089') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion2'" variant="secondary">{{ $t('wdm16.11090') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion2" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11091') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion3'" variant="secondary">{{ $t('wdm16.11092') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion3" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11093') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion4'" variant="secondary">{{ $t('wdm16.11112') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion4" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11094') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion5'" variant="secondary">{{ $t('wdm16.11113') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion5" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11095') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image11.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11130') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion10'" variant="secondary">{{ $t('wdm16.11114') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion10" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11096') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion11'" variant="secondary">{{ $t('wdm16.11115') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion11" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11097') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion12'" variant="secondary">{{ $t('wdm16.11116') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion12" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11098') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion13'" variant="secondary">{{ $t('wdm16.11117') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion13" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11099') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion14'" variant="secondary">{{ $t('wdm16.11118') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion14" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11100') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image12.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11131') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion20'" variant="secondary">{{ $t('wdm16.11119') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion20" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11101') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion21'" variant="secondary">{{ $t('wdm16.11120') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion21" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11102') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image8.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11132') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion30'" variant="secondary">{{ $t('wdm16.11121') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion30" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11103') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion31'" variant="secondary">{{ $t('wdm16.11122') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion31" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11104') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/projects/2560925.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11133') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion40'" variant="secondary">{{ $t('wdm16.11123') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion40" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11105') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image15.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.10995') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion50'" variant="secondary">{{ $t('wdm16.11124') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion50" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11106') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image17.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11134') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion51'" variant="secondary">{{ $t('wdm16.11125') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion51" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11107') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion52'" variant="secondary">{{ $t('wdm16.11126') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion52" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11108') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion53'" variant="secondary">{{ $t('wdm16.11127') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion53" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11109') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <img src="../../../static/img/slider/image18.png" style="width: 30px;" class="pull-right">
                <strong>{{ $t('wdm16.11135') }}</strong>
              </div>
              <div role="tablist">
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion61'" variant="secondary">{{ $t('wdm16.11128') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion61" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11110') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" bg-variant="dark">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-btn block href="#" v-b-toggle="'accordion62'" variant="secondary">{{ $t('wdm16.11129') }}</b-btn>
                  </b-card-header>
                  <b-collapse id="accordion62" :visible="false" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p class="card-text">{{ $t('wdm16.11111') }}</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <br>
        <web-bottom></web-bottom>
      </div>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import { mapGetters } from 'vuex';
export default {
  name: 'Contact',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {
      WisdomEraAdressMap: ''
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style scoped>
#WisdomEraMap {
  height: 300px;
  width: 100%;
}

</style>

